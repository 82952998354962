<script setup lang="ts">
import { chatService } from "./services";
import { getMessageFromMessageGTO } from "./utils/tools";
import { Dropdown, Modal, Compile } from '@components/common';
import { Message, store } from "./models";
import { onDeactivated, onMounted, reactive } from "vue";

const state = reactive({
  confirmModalIsOpen: false,
  deleteChatIndex: -1,
  inputText: ''
});

const onOpen = async () => {
  store.isOpen = true;
  store.chats = await chatService.getChats();
  if (store.chats?.length) {
    const chatId = store.chats[store.activeChatIndex].id;
    createChat(chatId);
  }
}

const createChat = async (id?: string) => {
  let chatDTO;
  if (id) {
    chatDTO = await chatService.getChat(id);
  }
  if (!chatDTO) {
    chatDTO = await chatService.createChat();
    store.chats.unshift(chatDTO);
    store.activeChatIndex = 0;
    const date = new Date();
    store.chats[0].name = `${date.toLocaleDateString("en-US")}, ${date.toLocaleTimeString("en-US")}`;
  }
  store.messages = getMessageFromMessageGTO(chatDTO.messages);
  chatService.init(chatDTO.userId, chatDTO.id);
}

const onClose = () => {
  store.isOpen = false;
} 

const onMessageWasSent = (message: Message) => {
  if (message.data.text) {
    store.message(message.data.text);
    store.asyncMessage(chatService.sendMessage({ type: 'text', text: message.data.text }));
  }
}

const onSelectChat = (index: number) => {
  if (store.activeChatIndex !== index) {
    const chat = store.chats[index];
    createChat(chat.id);
  }
  store.activeChatIndex = index;
}

const onCreateChat = () => {
  createChat();
}

const onAddInputText = (text: string) => {
  const inputElement = document.querySelector('form.sc-user-input > div') as HTMLElement;
  if (inputElement) {
    inputElement.focus();
    inputElement.textContent = text;
    const sel = document.getSelection();
    sel?.getRangeAt(0)['setStart'](inputElement, 1);
  }
}

const onSend = (text: string) => {
  onMessageWasSent({ id: '', author: 'me', type: 'text', data: { text }});
}

const listener = (event) => {
  store.clickEvent = event;
}

onMounted(() => {
  window.addEventListener('click', listener);
});

onDeactivated(() => {
  window.removeEventListener('click', listener);
});

const onDelete = async (e, proceed = false) => {
  if (proceed) {
    const chatId = store.chats[e].id;
    await chatService.deleteChat(chatId);
    store.deleteMessage(chatId);
    if (store.activeChatIndex === e) {
      onSelectChat(store.activeChatIndex++);
    }
    state.confirmModalIsOpen = false;
    state.deleteChatIndex = -1;
  } else {
    state.confirmModalIsOpen = true;
    state.deleteChatIndex = e;
  }
}

const onExpand = () => {
  store.extended = store.extended < 2 ? store.extended + 1 : 0;
}
</script>

<template>
  <div class="fc-cartie" :class="store.extended && 'fc-cartie--expanded-' + store.extended">
    <Beautiful-chat
      :participants="store.participants"
      :disableUserListToggle="true"
      :messageList="store.messages"
      :open="onOpen"
      :isOpen="store.isOpen"
      :close="onClose"
      :onMessageWasSent="onMessageWasSent"
    >
      <template #header>
        <div class="fc-cartie__header">
          <div class="fc-cartie__header-title">
            <Dropdown>
              <template #header>
                <span class="fc-cartie__header-name" v-if="store.chats.length">{{ store.chats[store.activeChatIndex].name }}</span>
              </template>
              <div class="fc-cartie__header-chats">
                <div class="fc-cartie__header-chats-item" v-for="(item, index) in store.chats" :key="index" @click="onSelectChat(index)">
                  {{ item.name }}
                  <Icon class="fc-cartie__header-chats-delete" name="trash" @click.stop="onDelete(index)"></Icon>
                </div>
                <Modal v-if="state.confirmModalIsOpen && state.deleteChatIndex > -1">                
                  <div>
                    Are you sure you want to delete {{ store.chats[state.deleteChatIndex].name }} chat ?
                  </div>
                  <template #footer>
                    <div class="fc-cartie__modal-actions">
                      <button @click="onDelete(state.deleteChatIndex, true)">delete</button>
                      <button @click="state.confirmModalIsOpen = false; state.deleteChatIndex - 1">cancel</button>
                    </div>
                  </template>
                </Modal>
              </div>
            </Dropdown>
            <Icon class="fc-cartie__header-create" name="plus" @click="onCreateChat"></Icon>
          </div>
          <Icon class="fc-cartie__header-expand" name="caret-sort" @click="onExpand"></Icon>
        </div>
      </template>
      <template #text-message-body="{ message }">
        <div v-if="!message.isLoading" class="fc-cartie__message">
          <span>{{ message.data.text }}</span>
          <div v-if="message.data.template">
            <Compile :template="message.data.template" :props="message.data.props" :messageId="message.id" @add="onAddInputText('add ingredient ')"></Compile>
          </div>
        </div>
        <div class="fc-cartie__loader" v-else>
          <span class="fc-cartie__loader-spinner"></span>
          <span class="fc-cartie__loader-text">{{ message.data.text }}</span>
        </div>
      </template>
      <template #footer="{ messages }">
        <div v-if="messages[messages.length - 1]?.data?.prompt">
          <Compile :template="messages[messages.length - 1].data.prompt" :props="messages[messages.length - 1].data.props" @select="onSend($event)"></Compile>
        </div>
      </template>
    </Beautiful-chat>
  </div>
</template>

<style scoped lang="scss">
.fc-cartie {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
      display: flex;
      align-items: center;
    }

    &-name {
      cursor: pointer;
      margin-right: 10px;
    }

    &-create {
      cursor: pointer;
    }

    &-expand {
      cursor: pointer;
      transform: rotate(90deg);
    }

    &-chats {
      background: white;
      color: rgb(86, 88, 103);
      border-radius: 10px;
      box-shadow: 0 7px 40px 2px rgba(148,149,150,.3);
      max-height: 500px;
      overflow: auto;
    
      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fff;
        padding: 10px;
        cursor: pointer;

        &:first-child {
          border-radius: 10px 10px 0 0;
        }

        &:last-child {
          border-radius: 0 0 10px 10px;
        }

        &:hover {
          background: #ccc;
        }
      }

      &-delete {
        margin-left: 8px;
        color: red;
        opacity: 0.5;
        cursor: pointer;
        transition: 300ms opacity;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &__modal-actions {
    button {
      cursor: pointer;
      margin-left: 10px;
    }
  }
  
  &__message {
    color: black;
    padding: 3px 0;
  }

  &__loader {
    display: flex;
    align-items: center;

    &-spinner {
      width: 25px;
      height: 25px;
      border: 5px dotted #CCC;
      border-radius: 50%;
      display: inline-block;
      position: relative;
      box-sizing: border-box;
      animation: rotation 2s linear infinite;
      margin-right: 12px;
    }

    &-text {
      color: #000;
    }
  }
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &--expanded-1 :deep() {
    .sc-message {
      width: 700px;
    }
    .sc-chat-window {
      width: 730px;
    }
  }

  &--expanded-2 :deep() {
    .sc-message {
      width: 900px;
    }
    .sc-chat-window {
      width: 930px;
    }
  }
}

:deep() {
  .sc-header--title {
    & > div > div:nth-child(2) {
      background: transparent;
    }
  }

  .sc-message {
    width: 500px;
  }
  
  .sc-chat-window {
    width: 530px;
    box-shadow: 0px 0px 11px -4px #3f3f3f;
  }
}
</style>